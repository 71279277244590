// import React from 'react';
// import '../App.css';
// import { Button } from './Button';
// import './HeroSection.css';

// function HeroSection() {
//   return (
//     <div className='hero-container'>
//       <video src='/videos/video-1.mp4' autoPlay loop muted />
//       <h1>ADVENTURE AWAITS</h1>
//       <p>What are you waiting for?</p>
//       <div className='hero-btns'>
//         <Button
//           className='btns'
//           buttonStyle='btn--outline'
//           buttonSize='btn--large'
//         >
//           GET STARTED
//         </Button>
//         <Button
//           className='btns'
//           buttonStyle='btn--primary'
//           buttonSize='btn--large'
//           onClick={console.log('hey')}
//         >
//           WATCH TRAILER <i className='far fa-play-circle' />
//         </Button>
//       </div>
//     </div>
//   );
// }

// export default HeroSection;


import React, { useRef } from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';

function HeroSection() {

  // const targetRef = useRef(null); // Create a reference for the target section
  const targetRef = useRef<HTMLDivElement | null>(null); // Create a reference for the target section

  const scrollToComponent = () => {
    window.scrollTo({
      top: targetRef.current.offsetTop,
      behavior: 'smooth',
    });
  };


  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      <h1>OpenThaiLLM</h1>
      <p>Large Language Model Thai Open Source Model</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={scrollToComponent}
        >
          GET STARTED
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          Learn about us 
          {/* Learn about us <i className='far fa-play-circle' /> */}
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;

