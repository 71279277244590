import React from 'react';
import '../../App.css';

export default function Products() {
  return <h1 className='products'>PRODUCTS</h1>;
}


// import React from 'react';
// import '../../App.css';
// import OpenThaiChatbot from '../OpenThaiChatbot'; // Adjust the path if necessary
// import ImageCaptioning from '../ImageCaptioning'; // Adjust the path if necessary
// import LLMTableQuery from '../Table'; // Adjust the path if necessary

// function Products() {
//   return (
//     <>
//     <OpenThaiChatbot />
//     <ImageCaptioning />
//     <LLMTableQuery />
//     </>
//   );
// }

// export default Products;
