import React from 'react';
import './partners.css';

const Partners = () => {
  return (
    <div className="trusted-clients-container">
      <h2>Our Partners</h2>
      <div className="clients-logos">
        <img src='images/AIAT_logo.png' alt="AIAT logo" />
        <img src='images/AIEAT_logo.png' alt="AIEAT logo" />
        <img src='images/Logo_of_NECTEC.svg.png' alt="NECTEC" />
      </div>
    </div>
  );
};

export default Partners;