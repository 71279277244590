import React, { useState } from 'react';
import './FAQ.css';

const FAQ = () => {
  const faqs = [
    { id: 1, category: "General Question", question: "How accurate is OpenThaiGPT’s Thai language AI models?", answer: "OpenThaiGPT's Thai language AI models benefit significantly from the volume and diversity of data used in their training. As the largest repository of Thai language data in the country, we are able to develop highly accurate models that effectively understand and generate Thai text" },
    { id: 2, category: "General Question", question: "How do I get started with OpenThaiGPT?", answer: "Getting started with OpenThaiGPT is simple. Contact our team through the website to discuss your needs and requirements. We’ll provide a tailored solution that fits your business objectives and help you implement it seamlessly." },
    { id: 3, category: "General Question", question: "What kind of support does OpenThaiGPT offer?", answer: "We offer comprehensive support to all our clients. This includes initial setup, integration assistance, training, and ongoing technical support to ensure you get the most out of our AI solutions." },
    { id: 4, category: "General Question", question: "Is my data safe with OpenThaiGPT?", answer: "Absolutely. We prioritize data security and privacy. Our systems comply with local and international data protection regulations, ensuring that your data is handled securely and confidentially." },
    { id: 5, category: "General Question", question: "How does OpenThaiGPT handle different Thai dialects??", answer: "We specialize in specific domains within the Thai computing industry. OpenThaiGPT can be specifically trained to understand and process multiple Thai dialects by projects." },
    { id: 6, category: "General Question", question: "What makes OpenThaiGPT different from other AI solutions?", answer: "OpenThaiGPT is specifically focused on the Thai language and culture, ensuring high accuracy and relevance for Thai users. Our solutions are customizable, secure, and designed to meet the unique needs of Thai businesses and individuals." }
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const groupedFAQs = faqs.reduce((acc, faq) => {
    acc[faq.category] = acc[faq.category] || [];
    acc[faq.category].push(faq);
    return acc;
  }, {});

  return (
    <div className="faq-container">
      {Object.keys(groupedFAQs).map(category => (
        <div key={category} className="faq-category">
          <h2>{category}</h2>
          <ul>
            {groupedFAQs[category].map((faq, index) => (
              <li key={faq.id} onClick={() => toggleFAQ(faq.id)} className="faq-item">
                <div className="faq-question">
                  {faq.question}
                  <span className="faq-toggle">{openIndex === faq.id ? '-' : '+'}</span>
                </div>
                {openIndex === faq.id && <div className="faq-answer">{faq.answer}</div>}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FAQ;