import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Partners from '../partners';
import AboutUs from '../AboutUs';
import WhyUs from '../WhyUs';
import FAQ from '../FAQ';
import OpenThaiChatbot from '../OpenThaiChatbot';
import ImageCaptioning from '../ImageCaptioning';
import LLMTableQuery from '../Table';

function Home() {
  return (
    <>
      <HeroSection />
      <Partners />
      <AboutUs />
      <WhyUs />
      <FAQ />
      {/* <Cards /> */}
      <OpenThaiChatbot />
      <ImageCaptioning />
      <LLMTableQuery />
      <Footer />
    </>
  );
}

export default Home;
