import React from 'react';
import './Table.css'; // Add this line to import your specific styles

// export default function LLMTableQuery() {
//   const openTableQueryDemo = () => {
//     window.open('https://your-demo-url.com', '_blank');
//   };
  
//   return (
//     <div className="table-query-container">
//       <h1 className="title">LLM Use Case 3: Table Query</h1>
//       <div className="content">
//         <p className="description">
//         ธุรกิจมีข้อมูลกระจัดกระจายเหมือนชิ้นส่วนปริศนา Data Analyst ซึ่งทำหน้าที่เป็นนักสืบข้อมูลจะกรองข้อมูลนี้เพื่อเปิดเผยรูปแบบที่ซ่อนอยู่ (ข้อมูลเชิงลึก) ข้อมูลเชิงลึกเหล่านี้เป็นชิ้นส่วนปริศนาที่ขาดหายไปซึ่งธุรกิจจำเป็นต้องใช้ในการตัดสินใจอย่างชาญฉลาดยิ่งขึ้น.
//         </p>
//         <p className="description">
//         เดิมที Data Analyst จำเป็นต้องมีทักษะการเขียนโค้ดเพื่อปลดล็อกความลับของข้อมูล ซึ่งน่าหงุดหงิดใจ กลไกสืบค้นเป็นตัวเปลี่ยนเกมเนื่องจากช่วยให้นักวิเคราะห์ถามคำถามเป็นภาษาไทยธรรมดาได้ โดยไม่จำเป็นต้องใช้การ coding skill ที่เหมือนเป็นภาษาที่ซับซ้อน ซึ่งช่วยประหยัดเวลาและความพยายามของนักวิเคราะห์
//         </p>
//         <p className="description">
//         Demo นี้นำเสนอ Concept ของการสืบค้นแบบตารางที่ปฏิวัติวงการซึ่งใช้ Natural Language Processing (NLP) แอปพลิเคชันนี้จะอนุญาตให้ผู้ใช้ถามคำถามเกี่ยวกับข้อมูลของตนได้โดยตรงในรูปแบบตารางโดยใช้ Natural Language ซึ่งช่วยลดความจำเป็นในการเขียนโค้ดและทำให้การสำรวจข้อมูลง่ายขึ้นสำหรับทั้งนักวิเคราะห์และผู้เริ่มต้นที่มีประสบการณ์
//         </p>
//         {/* <div className="flowchart">
//           <div className="box">User Query</div>
//           <div className="box">LLM</div>
//           <div className="box">SQL or Pandas Instructions</div>
//           <div className="box">Compiler</div>
//           <div className="box">Response Query</div>
//           <div className="box">Table Data</div>
//         </div> */}
//         <img src="images/Table.png" alt="Table Query" className="table-image" />
//         {/* <button className="demo-button" onClick={openTableQueryDemo}>ทดลอง Table Query Demo</button> */}
//       </div>
//     </div>
//   );
// }

const LLMTableQuery = () => {
  const openTableQueryDemo = () => {
    window.open('https://77a3-183-88-226-254.ngrok-free.app/', '_blank');
  };
  
  return (
    <div className="table-query-container">
      <h1 className="title">LLM Use Case 3: Table Query</h1>
      <div className="content">
        <p className="description">
        ธุรกิจมีข้อมูลกระจัดกระจายเหมือนชิ้นส่วนปริศนา Data Analyst ซึ่งทำหน้าที่เป็นนักสืบข้อมูลจะกรองข้อมูลนี้เพื่อเปิดเผยรูปแบบที่ซ่อนอยู่ (ข้อมูลเชิงลึก) ข้อมูลเชิงลึกเหล่านี้เป็นชิ้นส่วนปริศนาที่ขาดหายไปซึ่งธุรกิจจำเป็นต้องใช้ในการตัดสินใจอย่างชาญฉลาดยิ่งขึ้น.
        </p>
        <p className="description">
        เดิมที Data Analyst จำเป็นต้องมีทักษะการเขียนโค้ดเพื่อปลดล็อกความลับของข้อมูล ซึ่งน่าหงุดหงิดใจ กลไกสืบค้นเป็นตัวเปลี่ยนเกมเนื่องจากช่วยให้นักวิเคราะห์ถามคำถามเป็นภาษาไทยธรรมดาได้ โดยไม่จำเป็นต้องใช้การ coding skill ที่เหมือนเป็นภาษาที่ซับซ้อน ซึ่งช่วยประหยัดเวลาและความพยายามของนักวิเคราะห์
        </p>
        <p className="description">
        Demo นี้นำเสนอ Concept ของการสืบค้นแบบตารางที่ปฏิวัติวงการซึ่งใช้ Natural Language Processing (NLP) แอปพลิเคชันนี้จะอนุญาตให้ผู้ใช้ถามคำถามเกี่ยวกับข้อมูลของตนได้โดยตรงในรูปแบบตารางโดยใช้ Natural Language ซึ่งช่วยลดความจำเป็นในการเขียนโค้ดและทำให้การสำรวจข้อมูลง่ายขึ้นสำหรับทั้งนักวิเคราะห์และผู้เริ่มต้นที่มีประสบการณ์
        </p>
        {/* <div className="flowchart">
          <div className="box">User Query</div>
          <div className="box">LLM</div>
          <div className="box">SQL or Pandas Instructions</div>
          <div className="box">Compiler</div>
          <div className="box">Response Query</div>
          <div className="box">Table Data</div>
        </div> */}
        <div className='table-image-container'>
          <img src="images/Table.png" alt="Table Query" className="table-image" />
        </div>
        <button className="demo-button" onClick={openTableQueryDemo}>ทดลอง Table Query Demo</button>
      </div>
    </div>
  );
}

export default LLMTableQuery