import React from 'react';
import './WhyUs.css';

const WhyUs = () => {
  return (
    <div className="why-us-container">
      <div className="why-us-content">
        <div className="why-us-text">
          <h2>Why Us</h2>
          <ul>
            <li>By leveraging Thai data from Pantip, we ensure our AI deeply understands Thai expressions and context.</li>
            <li>Our AI is designed specifically to comprehend the nuances of the Thai language and culture.</li>
            <li>We are committed to supporting Thai creators by making our AI open source and accessible to everyone.</li>
          </ul>
        </div>
        <div className="why-us-image">
          <img src='images/thai-flag-speech-bubble.png' alt="Thai flag in a speech bubble" />
        </div>
      </div>
    </div>
  );
};

export default WhyUs;