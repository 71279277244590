import React from 'react';
// import '../../App.css';
import './ImageCaptioning.css'; // Add this line to import your specific styles

// export default function ImageCaptioning() {
//   const openChatbotDemo = () => {
//     window.open('https://23f0b5d8d349bf0687.gradio.live/', '_blank');
//   };
//   return (
//     <div className="captioning-container">
//       <div className="captioning-content">
//         <h1 className="captioning-title">LLM use case 2: Image Captioning</h1>
//         <p className="captioning-text">
//         ลองจินตนาการถึงโลกที่เต็มไปด้วยสถานที่ท่องเที่ยวที่สวยงาม ทิวทัศน์มากมายที่คุณไม่สามารถเข้าถึงได้ นั่นเป็นความจริงสำหรับคนตาบอดหลายคน แต่จะเกิดอะไรขึ้นเรามีวิธีปลดล็อกห้องนิรภัยนี้?
//         </p>
//         <p className="captioning-text">
//         Image Captioning ทำหน้าที่เหมือนนักแปลที่มีความสามารถ โดยนำภาพมาแปลงเป็นคำอธิบาย มันเหมือนกับการให้เพื่อนบรรยายภาพถ่ายให้คุณฟัง โดยให้รายละเอียดเกี่ยวกับฉาก สิ่งของ หรือแม้แต่อารมณ์ที่มันอยู่ในนั้น เทคโนโลยีนี้ดีมากๆ ช่วยให้คนตาบอดสามารถสำรวจโลกแห่งการมองเห็นในรูปแบบใหม่ เหมือนกับการเปิดประตูสู่มิติของข้อมูลที่ซ่อนอยู่ ส่งเสริมความรู้สึกเป็นอิสระและการไม่ถูกแบ่งแยก
//         </p>
//         <div className="captioning-image-container">
//           <div className="captioning-image-item">
//             <img src="images/image_captioning.png" alt="Image Captioning" className="captioning-image" />
//             {/* <p className="captioning-image-caption">อธิบายภาพนี้หน่อย</p> */}
//           </div>
//         </div>
//         <p className="captioning-text">
//           เอาล่ะ! มาลง Detail กันหน่อย Image Captioning ทำงานเหมือนกับนักสืบ (Detective) ที่กำลังวิเคราะห์ภาพถ่ายสถานที่เกิดเหตุ สิ่งแรกใช้ Convolutional Neural Network (CNN) โดยจะตรวจสอบภาพอย่างพิถีพิถัน ระบุวัตถุ สี และรายละเอียดอื่นๆ CNN นี้สร้างชุดข้อมูลหรือโค้ดที่สำคัญประกอบลำคัญของรูปภาพ จากนั้นอีกโปรแกรมหนึ่งก็คือ นักเล่าเรื่อง (storyteller) ที่มีทักษะในการใช้โค้ดจาก CNN และความรู้ทางภาษาของตัวเอง สร้างคำบรรยายแบบคำต่อคำ โดย storyteller จะอธิบาย พิจารณารูปภาพทั้งหมดและสร้างคำบรรยายที่ดีไปให้เหมาะกับจากมากที่สุด สุดท้ายผลลัพธ์ที่เราจะได้คือ คำบรรยายที่สมบูรณ์เชื่อมโยงข้อมูลภาพให้เป็นเรื่องราว นั้นและการเปิดโลก
//         </p>
//         {/* <button className="captioning-demo-button">ทดลอง Image captioning</button> */}
//       </div>
//     </div>
//   );
// }

const ImageCaptioning = () => {
  const ImageCaptioningtDemo = () => {
    window.open('https://23f0b5d8d349bf0687.gradio.live/', '_blank');
  };
  return (
    <div className="captioning-container">
      <div className="captioning-content">
        <h1 className="captioning-title">LLM use case 2: Image Captioning</h1>
        <p className="captioning-text">
        ลองจินตนาการถึงโลกที่เต็มไปด้วยสถานที่ท่องเที่ยวที่สวยงาม ทิวทัศน์มากมายที่คุณไม่สามารถเข้าถึงได้ นั่นเป็นความจริงสำหรับคนตาบอดหลายคน แต่จะเกิดอะไรขึ้นเรามีวิธีปลดล็อกห้องนิรภัยนี้?
        </p>
        <p className="captioning-text">
        Image Captioning ทำหน้าที่เหมือนนักแปลที่มีความสามารถ โดยนำภาพมาแปลงเป็นคำอธิบาย มันเหมือนกับการให้เพื่อนบรรยายภาพถ่ายให้คุณฟัง โดยให้รายละเอียดเกี่ยวกับฉาก สิ่งของ หรือแม้แต่อารมณ์ที่มันอยู่ในนั้น เทคโนโลยีนี้ดีมากๆ ช่วยให้คนตาบอดสามารถสำรวจโลกแห่งการมองเห็นในรูปแบบใหม่ เหมือนกับการเปิดประตูสู่มิติของข้อมูลที่ซ่อนอยู่ ส่งเสริมความรู้สึกเป็นอิสระและการไม่ถูกแบ่งแยก
        </p>
        <div className="captioning-image-container">
          <div className="captioning-image-item">
            <img src="images/image_captioning.png" alt="Image Captioning" className="captioning-image" />
            {/* <p className="captioning-image-caption">อธิบายภาพนี้หน่อย</p> */}
          </div>
        </div>
        <p className="captioning-text">
          เอาล่ะ! มาลง Detail กันหน่อย Image Captioning ทำงานเหมือนกับนักสืบ (Detective) ที่กำลังวิเคราะห์ภาพถ่ายสถานที่เกิดเหตุ สิ่งแรกใช้ Convolutional Neural Network (CNN) โดยจะตรวจสอบภาพอย่างพิถีพิถัน ระบุวัตถุ สี และรายละเอียดอื่นๆ CNN นี้สร้างชุดข้อมูลหรือโค้ดที่สำคัญประกอบลำคัญของรูปภาพ จากนั้นอีกโปรแกรมหนึ่งก็คือ นักเล่าเรื่อง (storyteller) ที่มีทักษะในการใช้โค้ดจาก CNN และความรู้ทางภาษาของตัวเอง สร้างคำบรรยายแบบคำต่อคำ โดย storyteller จะอธิบาย พิจารณารูปภาพทั้งหมดและสร้างคำบรรยายที่ดีไปให้เหมาะกับจากมากที่สุด สุดท้ายผลลัพธ์ที่เราจะได้คือ คำบรรยายที่สมบูรณ์เชื่อมโยงข้อมูลภาพให้เป็นเรื่องราว นั้นและการเปิดโลก
        </p>
        <button className="captioning-demo-button" onClick={ImageCaptioningtDemo} >ทดลอง Image captioning</button>
      </div>
    </div>
  );
}

export default ImageCaptioning