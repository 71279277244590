import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="who-we-are-container">
      <h1>Who we are</h1>
      <div className="who-we-are-content">
        <img src="images/business-concept-with-team-close-up.jpg" alt="Team collaboration" className="who-we-are-image" />
        <div className="who-we-are-text">
          <p>
            <strong>OpenThaiGPT</strong>, a collaboration between AIAT, AIEAT, and NECTEC, aims to create AI that truly understands Thai.
          </p>
          <p>
            Developing a Large Language Model (LLM) requires extensive resources, often impractical for smaller businesses.
          </p>
          <p>
            Our goal is to make this AI accessible to everyone in Thailand.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;