import React from 'react';
// import '../../App.css';
import './OpenThaiChatbot.css'; // Add this line to import your specific styles

// export default function OpenThaiChatbot() {
//   const openChatbotDemo = () => {
//     window.open('https://77a3-183-88-226-254.ngrok-free.app/chat', '_blank');
//   };
//   return (
//     <div className="chatbot-container">
//       <div className="chatbot-content">
//         <h1 className='chatbot-title'>LLM use case 1: Chatbot</h1>
//         <p className='chatbot-text'>
//           จำข่าวเหล่านี้ได้ไหมพวก ที่ว่า AI ชนะคนในเกมอย่างโกะและหมากรุก? อย่างเท่สุดๆไปเลยใช่ไหม แต่บางทีไกลตัวไปสะหน่อย แต่โมเดล GPT ของ OpenAI พลิกหน้ากระดานไปเลย อยู่ดีๆ AI ก็ดูเหมือนพวกเรามากขึ้น! มันคุยกับคนได้ และเหมือนมันจะเข้าใจข้อมูลในแบบที่เราไม่เคยเห็นมาก่อน
//         </p>
//         <p className='chatbot-text'>
//           นี่แหละที่ทำให้พวกเราที่ OpenThaiLLM ตื่นเต้นมากๆ เรากำลังพยายามทดลองโมเดลแชทด้วยเหมือนกัน! พวกเราลองปรับแต่งโมเดลต่างๆ เช่น โมเดลของพวกเรา OpenThaiGPT, โมเดล Typhoon หรือ SeaLLM และอีกหลายๆที่รองรับภาษาไทย เพื่อสร้างอะไรที่พิเศษจริงๆ และบอกเลยว่า เวอร์ชันล่าสุดของ Typhoon-8B ที่ train ต่อจาก Llama3 กำลังไปได้สวยในการพูดคุยภาษาไทย! พวกเราในฐานะที่อยู่ใน Generative AI Community ตอนนี้เราคิดว่ากำลังเดินมาถูกทางแล้วล่ะ!
//         </p>
//         <div className="chatbot-image-container">
//           <img src="/images/chatbot_demo.png" alt="Chatbot Demo" className="chatbot-image" />
//         </div>
//       </div>
//       <button className="chatbot-demo-button" onClick={openChatbotDemo}>ทดลอง Chatbot Demo</button>
//     </div>
//   );
// }


const openChatbotDemo = () => {
  // const OpenThaiChatbotLink = 'https://77a3-183-88-226-254.ngrok-free.app/chat'
  const OpenThaiChatbotLink = () => {
    window.open('https://e8ce-183-88-226-254.ngrok-free.app/', '_blank');
  };
  return (
    <div className="chatbot-container">
      <div className="chatbot-content">
        <h1 className='chatbot-title'>LLM use case 1: Chatbot</h1>
        <p className='chatbot-text'>
          จำข่าวเหล่านี้ได้ไหมพวก ที่ว่า AI ชนะคนในเกมอย่างโกะและหมากรุก? อย่างเท่สุดๆไปเลยใช่ไหม แต่บางทีไกลตัวไปสะหน่อย แต่โมเดล GPT ของ OpenAI พลิกหน้ากระดานไปเลย อยู่ดีๆ AI ก็ดูเหมือนพวกเรามากขึ้น! มันคุยกับคนได้ และเหมือนมันจะเข้าใจข้อมูลในแบบที่เราไม่เคยเห็นมาก่อน
        </p>
        <p className='chatbot-text'>
          นี่แหละที่ทำให้พวกเราที่ OpenThaiLLM ตื่นเต้นมากๆ เรากำลังพยายามทดลองโมเดลแชทด้วยเหมือนกัน! พวกเราลองปรับแต่งโมเดลต่างๆ เช่น โมเดลของพวกเรา OpenThaiGPT, โมเดล Typhoon หรือ SeaLLM และอีกหลายๆที่รองรับภาษาไทย เพื่อสร้างอะไรที่พิเศษจริงๆ และบอกเลยว่า เวอร์ชันล่าสุดของ Typhoon-8B ที่ train ต่อจาก Llama3 กำลังไปได้สวยในการพูดคุยภาษาไทย! พวกเราในฐานะที่อยู่ใน Generative AI Community ตอนนี้เราคิดว่ากำลังเดินมาถูกทางแล้วล่ะ!
        </p>
        <div className="chatbot-image-container">
          <img src="/images/chatbot_demo.png" alt="Chatbot Demo" className="chatbot-image" />
        </div>
      </div>
      
      <button className="chatbot-demo-button" onClick={OpenThaiChatbotLink}>ทดลอง Chatbot Demo</button>
    </div>
  );
}

export default openChatbotDemo